import { AfterContentChecked, Component, DoCheck, OnInit, TemplateRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

// Constants
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { APIConstants } from 'src/app/common/constants/callAPI-constants';
import { localStorageConstants } from 'src/app/common/constants/localStorage-constants';

// Service
import { CommonService } from '../../../common/services/service/common.service';
import { StorageService } from '../../../common/services/service/storage.service';
import { LogoutService } from '../../../common/services/service/logout.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styles: [],
})
export class HeaderComponent implements OnInit, DoCheck, AfterContentChecked {

	APIConstants = APIConstants;
	URLConstants = URLConstants;
	localStorageConstants = localStorageConstants;
	notificationCount: number = 0;
	notificationSubscription: any;

	modalRef: BsModalRef;
	headerData: any = { welcome: "Welcome back," };
	userName: string;
	photo: string;
	constructor(private commonService: CommonService, public router: Router, public storageService: StorageService, public logoutService: LogoutService, public modalService: BsModalService) {
		this.getNotificationCount();
	}

	ngOnInit() {
		this.notificationSubscription = this.commonService.notificationCount.subscribe((data) => {
			this.notificationCount = data;
		});
	}
	ngDoCheck() {
		this.photo = Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn)) ? this.storageService.getLocalToken(this.localStorageConstants.Photo) : this.storageService.getSessionToken(this.localStorageConstants.Photo);
	}
	ngAfterContentChecked() {
		this.commonService.getHeaderData().subscribe((res) => {
			this.headerData = res;
		});
	}

	getNotificationCount() {
		if (this.commonService.isBrowser()) {
			this.router.events.subscribe(event => {
				if (event instanceof NavigationEnd) {
					if ((this.storageService.getLocalToken(this.localStorageConstants.AccessToken) || this.storageService.getSessionToken(this.localStorageConstants.AccessToken))) {
						if (event.url !== '/') {
							this.commonService.callApi(this.APIConstants.GetNotificationCount, {}, 'get').then((success) => {
								if (!success.errors) {
									this.notificationCount = success.data;
								}
							});
						}
					}
				}
			});
		}
	}

	isSearchOpen: boolean = false;
	// Header Search
	openORcloseSearch(btnType) {
		this.isSearchOpen = btnType === 'open' ? true : false;
	}

	// Search Filter
	openSearchFilterModal(SearchFilterModal: TemplateRef<any>) {
		this.modalRef = this.modalService.show(SearchFilterModal, { class: 'modal-dialog-centered search-filter-modal' });
	}

	// Accreditations Filter
	openFilterModal(FilterModal: TemplateRef<any>) {
		this.modalRef = this.modalService.show(FilterModal, { class: 'modal-dialog-centered filter-modal' });
	}

	// Accreditations Sort
	openSortModal(SortModal: TemplateRef<any>) {
		this.modalRef = this.modalService.show(SortModal, { class: 'modal-dialog-centered sort-modal' });
	}

	// Quit Modal
	openQuitModal(QuitModal: TemplateRef<any>) {
		this.modalRef = this.modalService.show(QuitModal, { class: 'modal-dialog-centered quit-modal' });
	}

	// Upload Jobs Filter
	openJobFilterModal(JobFilterModal: TemplateRef<any>) {
		this.modalRef = this.modalService.show(JobFilterModal, { class: 'modal-dialog-centered job-filter-modal' });
	}

	previousNavigation(prev: any, id: any) {
		if (id) {
			this.commonService.encryptRoute(prev, id);
		} else {
			this.router.navigateByUrl(prev);
		}
	}

	ngOnDestroy() {
		this.notificationSubscription.unsubscribe();
	}

}
