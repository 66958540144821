<div class="maintenance-wrap">
    <div class="container">
        <div class="content-box">
            <div class="text-center">
                <div class="maintenance-img">
                    <img src="assets/images/maintenance.jpg" alt="Maintenance" />
                </div>
                <h2 class="h1">Scheduled Maintenance!</h2>
                <div class="content-info">
                    <p class="content-para">We are currently performing scheduled maintenance to enhance your experience. We apologize for any inconvenience caused. Please check back soon. Thank you for your patience.</p>
                </div>
                <button class="btn btn-primary mt-5" type="button" (click)="tryAgain()">Try again!</button>
            </div>
        </div>
    </div>
</div>