export const environment = {
    production: true,
    environmentName: 'dev',
    port: 4052,
    recaptcha: {
        siteKey: '6LckpXkgAAAAAJZB3qUHr_-adqDG53IkYF4Pufk9',
        secrectKey: '6LckpXkgAAAAAAL3BkbpRZw14dK5ubnOKYu9lPvS',
    },
    socketUrl: 'https://skillbase-socket.indianic.dev/webApp',
    localStorageEncryptionSecret: '!@#$&skillsbase&@dev!',
    apiUrl: 'https://skillsbase-api-dev.devpress.net/v1/api/',
    ipUrl: 'https://api.ipify.org?format=json',
    eventUrl: 'https://skillsbase.dexlytics.com/EventsManager'
};
