import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

// plugins
import swal from 'sweetalert2';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import * as CryptoJS from 'crypto-js';

// environment
import { environment } from 'src/environments/environment';
import { URLConstants } from '../../constants/routerLink-constants';

// service
import { StorageService } from './storage.service';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { EventConstants, localStorageConstants } from '../../constants/localStorage-constants';
import { ShowErrorService } from './show-error.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  authorised: any = false;
  public API_URL = '';
  public platformId: any;
  public accessToken = '';
  public notificationCount = new BehaviorSubject<any>(0);
  localStorageConstants = localStorageConstants;
  eventConstants = EventConstants;
  constructor(public router: Router, public injector: Injector, public Http: HttpClient, public storageService: StorageService, private spinnerService: NgxSpinnerService, public showErrorService: ShowErrorService) {
    this.API_URL = environment.apiUrl;
    this.platformId = injector.get(PLATFORM_ID);
  }

  /*******************************************************************************************
  @PURPOSE      	: Call api.
  @Parameters 	  : {
                      url : <url of api>
                      data : <data object (JSON)>
                      method : String (get, post)
                      isForm (Optional) : Boolean - to call api with form data
                      isPublic (Optional) : Boolean - to call api without auth header
                    }
  @RETURN         : Data obtain for API
  /*****************************************************************************************/
  public showSkeletonLoader: boolean;
  callApi(url, data, method, isPublic?, isForm?, html?): Promise<any> {
    this.accessToken = (Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn))) ? this.storageService.getLocalToken(this.localStorageConstants.AccessToken) : this.storageService.getSessionToken(this.localStorageConstants.AccessToken);

    this.spinnerService.show();
    this.showSkeletonLoader = true;
    let headers;
    if (isPublic) {
      headers = new HttpHeaders({ 'api-version': 'v2', 'content-Type': 'application/json' });
    } else if (html) {
      headers = new HttpHeaders({ 'api-version': 'v2', 'content-Type': 'text/html', 'Authorization': `Bearer ${this.accessToken}` });

    } else {
      headers = new HttpHeaders({ 'api-version': 'v2', 'content-Type': 'application/json', 'Authorization': `Bearer ${this.accessToken}` });
    }
    if (isForm) {
      headers = new HttpHeaders({ 'api-version': 'v2', 'Authorization': `Bearer ${this.accessToken}` });
    }
    return new Promise((resolve, reject) => {
      if (method === 'post') {
        this.Http.post(this.API_URL + url, data, { headers }).subscribe((value) => {
          this.showSkeletonLoader = false;
          this.spinnerService.hide();
          resolve(value);
        }, (error) => {
          this.spinnerService.hide();
          resolve(error.error);
          this.error(error);
        });
      } else if (method === 'get') {
        this.Http.get(this.API_URL + url, { headers, params: data }).subscribe((value) => {
          this.showSkeletonLoader = false;
          this.spinnerService.hide();
          resolve(value);
        }, (error) => {
          this.spinnerService.hide();
          resolve(error.error);
          this.error(error);
        });
      } else if (method === 'put') {
        this.Http.put(this.API_URL + url, data, { headers }).subscribe((value) => {
          this.showSkeletonLoader = false;
          this.spinnerService.hide();
          resolve(value);
        }, (error) => {
          this.spinnerService.hide();
          resolve(error.error);
          this.error(error);
        });
      } else if (method === 'delete') {
        const options = {
          headers: headers,
          body: data,
        };
        this.Http.delete(this.API_URL + url, data ? options : { headers }).subscribe((value) => {
          this.showSkeletonLoader = false;
          this.spinnerService.hide();
          resolve(value);
        }, (error) => {
          this.spinnerService.hide();
          resolve(error.error);
          this.error(error);
        });
      }
    });

  }

  callSCORMApi(url, data, method): Promise<any> {
    this.showSkeletonLoader = true;
    this.spinnerService.show();
    let headers;
    let authToken = btoa("GQVRGYI611:Kavubrt4ggycrPAoXQkNOEFnSKlWG2IAMaIxq2VT");
    headers = new HttpHeaders({ 'Authorization': `Basic ${authToken}` });

    return new Promise((resolve) => {
      if (method === 'post') {
        this.Http.post('https://cloud.scorm.com/api/v2/' + url, data, { headers }).subscribe({
          next: (value) => {
            this.showSkeletonLoader = false;
            this.spinnerService.hide();
            resolve(value);
          },
          error: (error) => {
            this.spinnerService.hide();
            resolve(error.error);
            // this.error(error);
          }
        });
      } else if (method === 'get') {
        this.Http.get('https://cloud.scorm.com/api/v2/' + url, { headers, params: data }).subscribe({
          next: (value) => {
            this.showSkeletonLoader = false;
            this.spinnerService.hide();
            resolve(value);
          },
          error: (error) => {
            this.spinnerService.hide();
            resolve(error.error);
            // this.error(error);
          }
        });
      } else if (method === 'put') {
        this.Http.put('https://cloud.scorm.com/api/v2/' + url, data, { headers }).subscribe({
          next: (value) => {
            this.showSkeletonLoader = false;
            this.spinnerService.hide();
            resolve(value);
          },
          error: (error) => {
            this.spinnerService.hide();
            resolve(error.error);
            // this.error(error);
          }
        });
      } else if (method === 'delete') {
        this.Http.delete('https://cloud.scorm.com/api/v2/' + url, { headers }).subscribe({
          next: (value) => {
            this.showSkeletonLoader = false;
            this.spinnerService.hide();
            resolve(value);
          },
          error: (error) => {
            this.spinnerService.hide();
            resolve(error.error);
            // this.error(error);
          }
        });
      }
    });
  }

  callApiObservable(url, data) {
    this.accessToken = (Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn))) ? this.storageService.getLocalToken(this.localStorageConstants.AccessToken) : this.storageService.getSessionToken(this.localStorageConstants.AccessToken);

    const headers = new HttpHeaders({ 'api-version': 'v2', 'content-Type': 'application/json', 'Authorization': `Bearer ${this.accessToken}` });
    return this.Http.post(this.API_URL + url, data, { headers }).pipe(map((rsp) => {
      return rsp;
    }));
  }
  /*****************************************************************************************/

  // *************************************************************//
  // @Purpose : To check server or browser
  // *************************************************************//
  isBrowser() {
    if (isPlatformBrowser(this.platformId)) {
      return true;
    } else {
      return false;
    }
  }
  /*****************************************************************************************/

  /*****************************************************************************************
  @PURPOSE      : To Show session LogOut popup
  @PARAMETERS   : NA
  @RETURN       : NA
  /*****************************************************************************************/
  sessionLogOut() {
    Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn)) ? this.storageService.clearLocalToken() : this.storageService.clearSessionToken();
    swal({
      position: 'center',
      type: 'error',
      text: 'Session Timeout',
      showConfirmButton: false,
      timer: 1800,
      customClass: 'custom-toaster',
    });
    this.router.navigate(['/']);
  }
  /****************************************************************************************/

  /*****************************************************************************************
  @PURPOSE      : To Show error on status 401, 422 or any other error
  @PARAMETERS   : NA
  @RETURN       : NA
  /*****************************************************************************************/
  error(error) {
    if (error.status === 401 || error.message == 'Invalid token.') {
      this.sessionLogOut();
    } else if (error.status === 503) {
      this.router.navigate([URLConstants.MAINTENANCE_MODE]);
    } else if (error.status === 500) {
      swal({
        position: 'center',
        type: 'error',
        text: 'Internal Server Error',
        showConfirmButton: false,
        timer: 1800,
        customClass: 'custom-toaster',
      });
    }
    else if (error.status === 404) {
      swal({
        position: 'center',
        type: 'error',
        text: error.error.errors.message,
        showConfirmButton: false,
        timer: 1800,
        customClass: 'custom-toaster',
      });
    }
    else {
      swal({
        position: 'center',
        type: 'error',
        text: 'Internal Server Error',
        showConfirmButton: false,
        timer: 1800,
        customClass: 'custom-toaster',
      });
    }
  }

  /*****************************************************************************************/

  /*****************************************************************************************
  @PURPOSE      : Get / Set all submodules data on edit 
  @PARAMETERS   : data
  @RETURN       : Decrypted Data
  /*****************************************************************************************/
  public headerData: BehaviorSubject<any> = new BehaviorSubject<any>({});
  getHeaderData(): Observable<any> {
    return this.headerData.asObservable();
  }
  setHeaderData(data) {
    this.headerData.next(data);
  }
  /****************************************************************************************/

  /*************************************************************
@PURPOSE  : To set notification count
/*************************************************************/
  setNotificationCount(count) {
    this.notificationCount.next(count);
  }

  /*****************************************************************************************
  @PURPOSE      : Used for encrypt the value
  @PARAMETERS   : data
  @RETURN       : Encrypted Data
  /*****************************************************************************************/
  encrypt(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key 123').toString();
  }

  /*****************************************************************************************
  @PURPOSE      : Used for decryption of encrypted code
  @PARAMETERS   : data
  @RETURN       : Decrypted Data
  /*****************************************************************************************/
  decrypt(data) {
    return JSON.parse(CryptoJS.AES.decrypt(data, 'secret key 123').toString(CryptoJS.enc.Utf8));
  }

  encryptRoute(path, urlId) {
    urlId = encodeURIComponent(this.encrypt(urlId));
    this.router.navigateByUrl(path + urlId);
  }

  eventManager(isGeneralEvent, ...event) {
    const metaData = this.getEventMetaData();
    let events = [];
    if (event.length > 1) {
      event.forEach((event, index) => {
        const eventData = this.getEventData(isGeneralEvent, event, true, index);
        events.push(eventData);
      });
    } else {
      events.push(this.getEventData(isGeneralEvent, event[0], false));
    }
    const params = { metaData, events: events };
    this.Http.post(environment.eventUrl, params).subscribe();
  }

  getEventData(isGeneralEvent, event, multi, index?) {
    const { currentTimeStamp, prevTimeStamp } = this.getTimeData();
    let eventData = event;
    if (isGeneralEvent) {
      eventData['CategoryCode'] = this.eventConstants.CategoryCodes.GeneralEvents;
      eventData['ListingName'] = null;
      eventData['ListingCode'] = null;
      eventData['param1'] = eventData.param1 ? eventData.param1 : null;
    }
    eventData = {
      ...eventData,
      TimeStamp: Math.floor(currentTimeStamp / 1000).toString(),
      TimeTaken: prevTimeStamp ? ((currentTimeStamp - prevTimeStamp) / 1000) : 0,
      SessionEventOrderNumber: multi ? index + 1 : null
    };
    return eventData;
  }

  getTimeData() {
    const KeepMeSignIn = Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn));
    const prevTimeStamp = KeepMeSignIn ? this.storageService.getLocalToken(this.localStorageConstants.TimeStamp) : this.storageService.getSessionToken(this.localStorageConstants.TimeStamp);
    const currentTimeStamp = new Date().getTime();
    KeepMeSignIn ? this.storageService.setLocalToken(this.localStorageConstants.TimeStamp, currentTimeStamp) : this.storageService.setSessionToken(this.localStorageConstants.TimeStamp, currentTimeStamp);
    return { currentTimeStamp, prevTimeStamp };
  }

  getEventMetaData() {
    const KeepMeSignIn = Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn));
    const metaData = {
      DeviceType: 'Web',
      DeviceName: 'N/A', // not for web
      OSVersion: 'N/A',// not for web
      AppVersion: 'N/A',// not for web
      ip: KeepMeSignIn ? this.storageService.getLocalToken(this.localStorageConstants.IP) : this.storageService.getSessionToken(this.localStorageConstants.IP),
      UserEmail: KeepMeSignIn ? this.storageService.getLocalToken(this.localStorageConstants.Email) : this.storageService.getSessionToken(this.localStorageConstants.Email),
      ManagerEmail: KeepMeSignIn ? this.storageService.getLocalToken(this.localStorageConstants.ManagerEmail) : this.storageService.getSessionToken(this.localStorageConstants.ManagerEmail),
      SessionID: KeepMeSignIn ? this.storageService.getLocalToken(this.localStorageConstants.SessionID) : this.storageService.getSessionToken(this.localStorageConstants.SessionID),
      Operator: KeepMeSignIn ? this.storageService.getLocalToken(this.localStorageConstants.OperatorName) : this.storageService.getSessionToken(this.localStorageConstants.OperatorName),
      Operator_id: KeepMeSignIn ? this.storageService.getLocalToken(this.localStorageConstants.OperatorId) : this.storageService.getSessionToken(this.localStorageConstants.OperatorId)
    };
    return metaData;
  }

  getIP() {
    const ip = Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn)) ? this.storageService.getLocalToken(this.localStorageConstants.IP) : this.storageService.getSessionToken(this.localStorageConstants.IP);
    if (!ip) {
      this.Http.get(environment.ipUrl).subscribe((data: any) => {
        Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn)) ? this.storageService.setLocalToken(this.localStorageConstants.IP, data.ip) : this.storageService.setSessionToken(this.localStorageConstants.IP, data.ip);
      });
    }
  }

  randomString(len = 30) {
    const DATA = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let sb = '';

    for (let i = 0; i < len; i++) {
      sb += DATA.charAt(Math.floor(Math.random() * DATA.length));
    }

    return sb;
  }

  checkModuleLock(info) {
    // if (info.module_lock && !info.user_module_lock) {
    //   this.showErrorService.popToast('error', 'This module is locked, please complete previous module to unlock this module.');
    //   return false;
    // } else 
    if (info.user_module_lock) {
      if (info.unlock_date_time && info.prerequsite_date_time) {
        this.showErrorService.popToast('error', info.unlock_date_time);
        return false;
      } else if (info.module_prerequsite_date_time) {
        this.showErrorService.popToast('error', 'This module is locked, please complete previous module to unlock this module.');
        return false;
      } else {
        if (info.unlock_date_time == null || info.unlock_date_time == "") {
          this.showErrorService.popToast('error', 'This module is locked, please complete previous module to unlock this module.');
        } else {
          const dateStr = new DatePipe('en-US').transform(info.unlock_date_time, 'dd/MM/yyyy, hh:mm a');
          this.showErrorService.popToast('error', `This module will unlock on ${dateStr}`);
        }
        return false;
      }
    } else {
      return true;
    }
  }

  checksubModuleLock(info) {
    // if (info.submodule_lock && !info.user_submodule_lock) {
    //   this.showErrorService.popToast('error', 'The sub-module is locked, please complete previous sub-module to unlock this sub-module.');
    //   return false;
    // } else 
    if (info.user_submodule_lock) {
      if (info.submodule_unlock_date_time && info.prerequsite_date_time) {
        this.showErrorService.popToast('error', info.submodule_unlock_date_time);
        return false;
      } else if (info.submodule_prerequsite_date_time) {
        this.showErrorService.popToast('error', 'This sub-module is locked, please complete previous sub-module to unlock this sub-module.');
        return false;
      } else {
        if (info.unlock_date_time != null || info.unlock_date_time != "") {
          const dateStr = new DatePipe('en-US').transform(info.unlock_date_time, 'dd/MM/yyyy, hh:mm a');
          this.showErrorService.popToast('error', `This submodule will unlock on ${dateStr}`);
        } else {
          this.showErrorService.popToast('error', 'This submodule is locked, please complete previous submodule to unlock this submodule.');
        }
        return false;
      }
    } else {
      return true;
    }
  }

}
