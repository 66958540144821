export const URLConstants = {
    SWITCH_OPERATOR: '/switch-operator',
    HOME: '/',
    REGISTER: '/register',
    DASHBOARD: '/dashboard',
    MY_ACCOUNT: '/my-account',
    MANAGE_PASSWORD: '/my-account/manage-password',
    ACCREDATION: '/my-account/accredation',
    NOTIFICATION_SETTINGS: '/my-account/notification-settings',
    CONTACT: '/contact-us',
    HELP: '/help',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_CONDITIONS: '/terms-&-conditions',
    NOTIFICATION: '/notifications',
    SEARCH: '/search-result',
    LOGIN: '/login',
    FORGOT_PASS: '/forgot-password',
    VERIFY_CODE: '/verify-code',
    MAINTENANCE_MODE: '/maintenance-mode',
    CertificateValidator: '/certValidator',



    // Courses routing
    COURSES: '/courses',
    SKILLSBASE_COURSES: '/courses/skillsbase-original',
    VIEW_COURSE_DETAIL: '/courses/view-course/',
    VIEW_MODULE_DETAIL: '/courses/view-module/',
    // Submodules routing
    SLIDE1: '/courses/slide1',
    SLIDE2: '/courses/slide2',
    SLIDE3: '/courses/slide3',
    SLIDE4: '/courses/slide4',
    SLIDE5: '/courses/slide5',
    SLIDE6: '/courses/slide6',
    SLIDE7: '/courses/slide7',
    SLIDE8: '/courses/slide8',
    SLIDE9: '/courses/slide9',
    SLIDE10: '/courses/slide10',
    SLIDE11: '/courses/slide11',
    SLIDE12: '/courses/slide12',
    SLIDE13: '/courses/slide13',
    PRODUCT_DETAIL: '/courses/view-product/',
    // Quiz Submodule Routing
    QUIZ_WELCOME: '/courses/quiz',
    // QUIZ_INITIATE: '/courses/quiz/intiate',
    // QUIZ_COURSE_DETAILS: '/courses/quiz/course-show',
    QUIZ_PREVIOUS_RESULT: '/courses/quiz/previous-result/',
    VIEW_QUIZ_PDF: '/courses/quiz/view-quiz-result/',
    // SCORM Submodule Routing
    SCORM_WELCOME: '/courses/scorm',
    // SCORM_INITIATE: '/courses/scorm/intiate',
    // SCORM_COURSE_DETAILS: '/courses/scorm/course-show',
    SCORM_PREVIOUS_RESULT: '/courses/scorm/previous-result/',

    // Products routing
    PRODUCTS: '/products',
    FEATURED_PRODUCTS: '/products/featured',
    VENDOR_PRODUCTS: '/products/vendor-products/1',
    VIEW_PRODUCTS: '/products/view-product/1',
    PRODUCT_FEEDBACK: '/products/feedback/1',
    PRODUCT_RESOURCES: '/products/resourses/1',


    //MFA Routing
    MFA_PAGE: '/multi-factor-auth',

    // My List routing
    MY_LIST: '/my-list',
    VIEW_LIST: '/my-list/detail',

    // Jobs
    JOBS_LIST: '/jobs',
    JOBS_DETAIL: '/jobs/detail',

    // News routing
    NEWS: 'news',
    VIEW_NEWS: '/news/view-news/1'
};
