import { environment } from "src/environments/environment";

export const localStorageConstants = {
    IsRememberMe: 'isRememberMe',
    KeepMeSignIn: 'keepMeSignIn',
    AccessToken: 'accessToken',
    Name: 'name',
    Email: 'email',
    Pass: 'Pass',
    Photo: 'photo',
    Notification: 'notification',
    OperatorId: 'operatorId',
    UserId: 'userId',
    OperatorName: 'operatorName',
    OperatorLogo: 'operatorLogo',
    //MFA
    EnableMFA: 'enableMFA',
    QRCode: 'qr_code',
    MFAUserId: 'mfa_user_id',
    ID: 'id',
    // Submodule keys
    SubmoduleInfo: 'subModule_info',
    QuizResultPDFurl: 'quizResultPDFurl',
    ManagerEmail: 'managerEmail',
    SessionID: 'sessionId',
    IP: 'ip',
    TimeStamp: 'timestamp'
};
export const SECURE_LS = {
    ENCODING_TYPE: 'aes',
    STORAGE_KEY: '__secure__sb__ls__',
    ENCRYPTION_SECRET: environment.localStorageEncryptionSecret,
};
export const EventConstants = {
    Event: {
        Opened: { name: 'Opened', code: 5 },
        Clicked: { name: 'Clicked', code: 9 },
        OpenedModule: { name: 'OpenedModule', code: 16 },
        FinishedQuiz: { name: 'FinishedQuiz', code: 20 },
    },
    CategoryCodes: {
        GeneralEvents: 1,
        CourseEvents: 2
    },
    Screen: {
        Home: { name: 'Home', code: 1 },
        Profile: { name: 'My Account', code: 3 },
        Help: { name: 'Help Screen', code: 5 },
        Contact: { name: 'Contact Us', code: 6 },
        Notification: { name: 'Notifications', code: 7 },
        Login: { name: 'Login', code: 18 },
        Signup: { name: 'Sign Up', code: 19 },
        Operator: { name: 'Operator List', code: 35 },
        Assessment: { name: 'Training Courses', code:  14},
    },
    ParameterActionName: {
        Contact: { name: 'Contact Us', code: 3 },
        Notification: { name: 'Notifications', code: 4 },
        Profile: { name: 'My Account', code: 6 }
    }
};