import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// service
import { CommonService } from './service/common.service';
import { StorageService } from './service/storage.service';
import { ShowErrorService } from './service/show-error.service';
import { RestrictKeyPressService } from './service/restrict-key-press.service';
import { LogoutService } from './service/logout.service';
import { CustomValidationService } from './service/custom-validation.service';

// TS Files
import { ErrorMessages } from './TS-files/errorMessages';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    CommonService,
    StorageService,
    ShowErrorService,
    ErrorMessages,
    RestrictKeyPressService,
    LogoutService,
    CustomValidationService
  ]
})
export class ServiceModule { }
