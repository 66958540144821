import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/** Directives */
import { IframeTrackerDirective } from "../directives/iframe-tracker.directive"
/** Pipes */
import { SafeHtmlPipe, SafeUrlPipe } from '../../common/services/service/common-pipe.pipe';
/** Component */
import { HeaderComponent } from '../components/header/header.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
/** Plugins */
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { RatingModule } from 'ngx-bootstrap/rating'
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { OwlModule } from 'ngx-owl-carousel';
import { NgSelectModule } from '@ng-select/ng-select';
import { QRCodeModule } from 'angularx-qrcode';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { HighchartsChartModule } from 'highcharts-angular';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ImageCropperModule } from 'ngx-image-cropper';
import { EmbedVideo } from 'ngx-embed-video';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MFAModalComponent } from '../components/mfa-modal/mfa-modal.component';

@NgModule({
  declarations: [
    /** Directives */
    IframeTrackerDirective,
    /** Pipes */
    SafeHtmlPipe,
    SafeUrlPipe,
    /** Components */
    HeaderComponent,
    SidebarComponent,
    MFAModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    ModalModule.forRoot(),
    OwlModule,
    NgSelectModule,
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    ProgressbarModule.forRoot(),
    HighchartsChartModule,
    QRCodeModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    NgCircleProgressModule.forRoot(),
    SlickCarouselModule,
    NgxSpinnerModule,
    ImageCropperModule,
    EmbedVideo.forRoot(),
    InfiniteScrollModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    /** Directives */
    IframeTrackerDirective,
    /** Pipes */
    SafeHtmlPipe,
    SafeUrlPipe,
    /** Components */
    HeaderComponent,
    SidebarComponent,
    MFAModalComponent,
    /** Plugins */
    OwlModule,
    NgSelectModule,
    RatingModule,
    TabsModule,
    ProgressbarModule,
    HighchartsChartModule,
    QRCodeModule,
    TooltipModule,
    BsDropdownModule,
    AccordionModule,
    NgCircleProgressModule,
    SlickCarouselModule,
    NgxSpinnerModule,
    ImageCropperModule,
    EmbedVideo,
    InfiniteScrollModule
  ],
  providers: [BsModalRef],
})
export class SharedModule { }
