import { isPlatformBrowser } from '@angular/common';
import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { SECURE_LS } from '../../constants/localStorage-constants';
import * as SecureLS from 'secure-ls';
import * as CryptoJS from 'crypto-js';

@Injectable({
	providedIn: 'root',
})
export class StorageService {

	public platformId: any;
	private ls = new SecureLS({ encryptionSecret: SECURE_LS.ENCRYPTION_SECRET });

	constructor(injector: Injector) {
		this.platformId = injector.get(PLATFORM_ID);
	}

	/*************************************************************
		@PURPOSE      : We can use following function to use localstorage
		@PARAMETERS   : key, value
		@RETURN       : NA
	/*************************************************************/
	getStorage() {
		if (isPlatformBrowser(this.platformId))
			return this.ls.get(SECURE_LS.STORAGE_KEY);
	}
	initialiseLS(keys) {
		if (isPlatformBrowser(this.platformId)) {
			this.ls.set(SECURE_LS.STORAGE_KEY, keys);
		}
	}

	setLocalToken(key, value) {
		if (isPlatformBrowser(this.platformId)) {
			let obj = this.getStorage();
			if (obj) {
				obj[key] = value;
				this.initialiseLS(obj);
			}
			else
				this.initialiseLS({ [key]: value });
		}
	}
	getLocalToken(key) {
		if (isPlatformBrowser(this.platformId)) {
			let lsItems = this.getStorage();
			return lsItems[key];
		}
	}
	removeLocalToken(key) {
		if (isPlatformBrowser(this.platformId)) {
			let lsItems = this.getStorage();
			delete lsItems[key];
			this.initialiseLS(lsItems);
			// this.ls.remove(key);
		}
	}
	clearLocalToken() {
		if (isPlatformBrowser(this.platformId)) {
			this.ls.clear();
		}
	}
	/*************************************************************/

	/*************************************************************
		@PURPOSE      : We can use following function to use sessionstorage
		@PARAMETERS   : key, value
		@RETURN       : NA
	/*************************************************************/
	encrypt(data) {
		return CryptoJS.AES.encrypt(JSON.stringify(data), SECURE_LS.ENCRYPTION_SECRET).toString();
	}
	decrypt(data) {
		return JSON.parse(CryptoJS.AES.decrypt(data, SECURE_LS.ENCRYPTION_SECRET).toString(CryptoJS.enc.Utf8));
	}
	setSessionToken(key, value) {
		if (isPlatformBrowser(this.platformId)) {
			sessionStorage.setItem(key, this.encrypt(value));
		}
	}
	getSessionToken(key) {
		if (isPlatformBrowser(this.platformId)) {
			if (sessionStorage.getItem(key)) {
				return this.decrypt(sessionStorage.getItem(key));
			}
		}
	}
	removeSessionToken(key) {
		if (isPlatformBrowser(this.platformId)) {
			sessionStorage.removeItem(key);
		}
	}
	clearSessionToken() {
		if (isPlatformBrowser(this.platformId)) {
			sessionStorage.clear();
		}
	}
	/*************************************************************/
}
