import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'dateFilter'
})
export class DateFilterPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }
  transform(value: any, format?: string): any {
    if (!value) {
      return '';
    }
    format = format || 'short';
    return value && typeof (value) === 'object' ? this.datePipe.transform(value, format) : '';
  }
}

@Pipe({ name: 'htmlToText' })
export class HtmlToTextPipe implements PipeTransform {
  transform(value: any): string {
    const temp = document.createElement('p');
    temp.innerHTML = value;
    return temp.textContent || temp.innerText || '';
    // return text ? text.replace(/]+>/gm, '') : '';
  }
}


@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
