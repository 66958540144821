import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomValidationService {

  constructor() { }

  validateEmail(email) {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const re = new RegExp(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i);
      const valid = re.test(email);
      return valid ? null : { invalidInput: true };
    }
  }

  /**
  * Password validator using pattern
  * @param form control
  * @output boolean
  */
  passwordPatternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      /**
       * Regex to validate password that must contain at least three character categories among the following
       * a-z
       * A-Z
       * 0-9
       * special chars `~:;'\"|\\-!@#$%^?&*()_[\/\\]{},.<>+=
       */
      const regex = new RegExp('^((?=.*[A-Z])(?=.*[a-z])(?=.*\\d)|(?=.*[a-z])(?=.*\\d)(?=.*[`~:;\'"|\\-!@#$%^?&*()_[\/\\]{},.<>+=])|(?=.*[A-Z])(?=.*\\d)(?=.*[`~:;\'"|\\-!@#$%^?&*()_[\/\\]{},.<>+=])|(?=.*[A-Z])(?=.*[a-z])(?=.*[`~:;\'"|\\-!@#$%^?&*()_[\/\\]{},.<>+=])).{8,}$', 'g');
      const valid = regex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }

  passwordRequirmentPatternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      /**
       * Regex to validate password that must contain at least three character categories among the following
       * a-z
       * A-Z
       * 0-9
       * special chars `~:;'\"|\\-!@#$%^?&*()_[\/\\]{},.<>+=
       */
      //for uppercase
      const upperCaseRegex = new RegExp('^(?=.*[A-Z])')
      const isUpperCaseValid = upperCaseRegex.test(control.value);
      //for lowercase
      const lowerCaseRegex = new RegExp('^(?=.*[a-z])')
      const isLowerCaseValid = lowerCaseRegex.test(control.value);
      //for Number
      const numberRegex = new RegExp('^(?=.*[0-9])')
      const isNumberValid = numberRegex.test(control.value);
      //for special Character
      const specialCharRegex = new RegExp('^(?=.*[`~:;\'"|\\-!@#$%^?&*()_[\/\\]\\\\{},.<>+=])')
      const isSpecialCharValid = specialCharRegex.test(control.value);
      //for length
      const lengthRegex = new RegExp('^(.{8,}$)')
      const islengthValid = lengthRegex.test(control.value);

      return { isUpperCaseValid: isUpperCaseValid, isLowerCaseValid: isLowerCaseValid, isNumberValid: isNumberValid, isSpecialCharValid: isSpecialCharValid, islengthValid: islengthValid };
    };
  }


  matchPassword(password: string, confirmPassword: string) {
    return (formGroup: UntypedFormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    };
  }

}
