import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

// Constants
import { URLConstants } from '../../constants/routerLink-constants';
import { localStorageConstants } from '../../constants/localStorage-constants';

// Service
import { StorageService } from '../service/storage.service';

/****************************************************************************
@PURPOSE      : Dont allow public pages to get accessed. (After Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanLoginActivate {
  URLConstants = URLConstants;
  localStorageConstants = localStorageConstants;
  constructor(public storageService: StorageService, public router: Router) { }
  canActivate() {
    let isMFAToken = (Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn)) && (this.storageService.getLocalToken('MFA_is_valid') || this.storageService.getLocalToken('MFA_is_valid') != undefined)) ? 1 :
    (this.storageService.getLocalToken('MFA_is_valid') || this.storageService.getLocalToken('MFA_is_valid') != undefined) ? this.storageService.getLocalToken('MFA_is_valid') : 0;

    let isToken = Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn)) ? this.storageService.getLocalToken(this.localStorageConstants.AccessToken) : this.storageService.getSessionToken(this.localStorageConstants.AccessToken);

    if (!isToken || !isMFAToken) {
      return true;
    }
    this.router.navigate([this.URLConstants.DASHBOARD]);
    return false;
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Dont allow authorized pages to get accessed.  (Before Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanAuthActivate {
  URLConstants = URLConstants;
  localStorageConstants = localStorageConstants;
  constructor(public storageService: StorageService, public router: Router) { }
  canActivate() {
    let isToken = Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn)) ? this.storageService.getLocalToken(this.localStorageConstants.AccessToken) : this.storageService.getSessionToken(this.localStorageConstants.AccessToken);
    let mfaEnabled = Number( this.storageService.getLocalToken('mfaEnabled'));
    
    if(mfaEnabled){
      let isMFAToken = (Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn)) && (this.storageService.getLocalToken('MFA_is_valid') || this.storageService.getLocalToken('MFA_is_valid') != undefined)) ? 1 :
      (this.storageService.getLocalToken('MFA_is_valid') || this.storageService.getLocalToken('MFA_is_valid') != undefined) ? this.storageService.getLocalToken('MFA_is_valid') : 0;
      if(isMFAToken && isToken){
        return true;
      }
      this.router.navigate([this.URLConstants.LOGIN]);
      return false;
    } else {
      if (isToken) {
        return true;
      }
      this.router.navigate([this.URLConstants.LOGIN]);
      return false;
    }

  }
}
/****************************************************************************/
