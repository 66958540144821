import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Constants
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { localStorageConstants } from 'src/app/common/constants/localStorage-constants';

//Services
import { StorageService } from '../../../common/services/service/storage.service';
import { CommonService } from 'src/app/common/services/service/common.service';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { RestrictKeyPressService } from 'src/app/common/services/service/restrict-key-press.service';
import { APIConstants } from 'src/app/common/constants/callAPI-constants';
import { messages } from 'src/app/common/constants/errorMessagesData';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa-modal.component.html',
  styleUrls: []
})
export class MFAModalComponent implements OnInit {

    @Input() userDetails: any;
    @Input() is_MFA_enabled: boolean;
    localStorageConstants = localStorageConstants;
    URLConstants = URLConstants;
    APIConstants = APIConstants;
    messages = messages;
    submitted: boolean = false;
    passwordSubmitted: boolean = false;
    public showPassword: boolean = false;
    public password: boolean = true;

  
  
    public QRCode: string;
    public MFAUserId: string;
    public enableMFA: boolean;
    public id: any;
    public MFA: any = {};
  
  
    constructor(
      private commonService: CommonService,
      private router: Router,
      private storageService: StorageService,
      private showErrorService: ShowErrorService,
      public modalRef: BsModalRef,
      public modalService: BsModalService,
      public restrictKeyPressService: RestrictKeyPressService) {
        this.QRCode = this.userDetails?.qr_code;
        this.MFAUserId = this.userDetails?.mfa_user_id;
        this.enableMFA = this.userDetails?.is_mfa_enable;
        this.id = this.storageService.getLocalToken(this.localStorageConstants.ID);
    }

  ngOnInit() {
   }

  callEnableMFA(form?){
    this.passwordSubmitted = true;
    if (form.valid) {
      if (this.is_MFA_enabled) {
        const user = {
          email: this.userDetails.email,
          password: this.userDetails.password,
        };
        this.commonService.callApi(this.APIConstants.EnableMFA, user, 'post', false, false, false).then((success) => {
          if (!success.errors) {
            this.MFAUserId = success.data.mfa_user_id;
            this.QRCode = success.data.qr_code;
            this.password = false;
          } else {
            this.showErrorService.popToast('error', success.errors.message);
          }
        });
      } else {
        const user = {
          mfa_user_id: this.userDetails.mfa_user_id ? this.userDetails.mfa_user_id : this.MFAUserId
        };
        this.commonService.callApi(this.APIConstants.DisableMFA, user, 'post', false, false, false).then((success) => {
          if (!success.errors) {
            this.modalService.hide();
            this.showErrorService.popToast('success', success.data.message);
          } else {
            this.showErrorService.popToast('error', success.errors.message);
          }
        });
      }
    }
  }

  verifyMFA(form?){
    this.submitted = true;
    this.MFA.password = this.userDetails.password;
    this.MFA.mfa_user_id = this.userDetails.mfa_user_id ? this.userDetails.mfa_user_id : this.MFAUserId;
    if (form.valid) {
      this.commonService.callApi(this.APIConstants.VerifyMFA, this.MFA, 'post', false, false, false).then((success) => {
        if (!success.errors) {
            this.showErrorService.popToast('success', 'MFA authentication enabled successfully.');
          this.modalService.hide();
        } else {
          this.storageService.setLocalToken('MFA_is_valid', 0);
          this.showErrorService.popToast('error', success.errors.message);
        }
      });
    }
  }

}





