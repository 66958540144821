import { Component, DoCheck, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// Constants
import { localStorageConstants } from 'src/app/common/constants/localStorage-constants';

// Services
import { CommonService } from './common/services/service/common.service';
import { StorageService } from './common/services/service/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, DoCheck {

  public accessToken: string;
  localStorageConstants = localStorageConstants;
  isMaintenancePage: boolean;
  constructor(private storageService: StorageService, private commonService: CommonService, private router: Router) {
    if (this.commonService.isBrowser()) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if(event.url == '/maintenance-mode' || event.url == '/login' || event.url == '/certValidator' || event.url == '/'  || event.url == '/multi-factor-auth' || event.url == '/register' || event.url == '/forgot-password'){
            this.isMaintenancePage = true
          } else {
            this.isMaintenancePage = false
          }
          window.scrollTo(0, 0);
        }
      });
    }
  }

  ngOnInit() { }

  ngDoCheck() {
    this.accessToken = Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn)) ? this.storageService.getLocalToken(this.localStorageConstants.AccessToken) : this.storageService.getSessionToken(this.localStorageConstants.AccessToken);
  }

}
