import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// constants
import { APIConstants } from '../../constants/callAPI-constants';
import { URLConstants } from '../../constants/routerLink-constants';

// service
import { CommonService } from './common.service';
import { StorageService } from './storage.service';

@Injectable({
	providedIn: 'root',
})
export class LogoutService {
	URLConstants = URLConstants;
	APIConstants = APIConstants;

	constructor(public storageService: StorageService, private router: Router, private commonService: CommonService) { }

	/*************************************************************
	@PURPOSE      : Logout
	@PARAMETERS   : NA
	@RETURN       : NA
	/*************************************************************/
	logout() {
		this.commonService.callApi(this.APIConstants.Logout, '', 'post', false, false, false).then((success) => {
			if (!success.errors) {
				this.storageService.clearLocalToken();
				this.storageService.clearSessionToken();
				this.router.navigate(['/']);
			}
		});
	}
	/*************************************************************/
}
